<template>
  <div class="row">
    <b-modal ref="groupsmodal" no-enforce-focus hide-header>
      <form @submit.prevent="saveGroups">
        <b-form-group label="Card Groups" label-for="groups">
          <blueprint-search-input
            id="groups"
            v-model="selectedItem.groups"
            placeholder="Type to search"
            api-route-path="groups"
            :state="groupsState('groups')"
            :multiple="true"
          />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="saveGroups()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <div class="col">
      <b-card title="Cards">
        <blueprint-data api-route-path="cards" :fields="fields" :where="where" :q="q">
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Search">
              <b-form-input v-model="q" type="text" placeholder="Search by name" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Groups">
              <blueprint-search-input
                id="groups"
                v-model="groups"
                :multiple="true"
                placeholder="Type to search Groups"
                label="name"
                track-by="id"
                api-route-path="groups"
              />
            </b-form-group>
          </template>
          <template v-slot:actions="{ item }">
            <a href="#" @click.prevent="cardGroups(item)"><fa-icon icon="user-friends" />Groups</a>
          </template>
          <template v-slot:default="{ state, item }">
            <b-form-group label="Firstname" label-for="firstname">
              <b-form-input id="firstname" v-model="item.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
            </b-form-group>
            <b-form-group label="Lastname" label-for="lastname">
              <b-form-input id="lastname" v-model="item.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
            </b-form-group>
            <b-form-group label="E-mail" label-for="email">
              <b-form-input id="email" v-model="item.email" :state="state('email')" type="email" placeholder="E-mail address" />
            </b-form-group>
            <b-form-group label="Phone" label-for="phone">
              <b-form-input id="phone" v-model="item.phone" :state="state('phone')" type="tel" placeholder="Phone number with country code" />
            </b-form-group>
            <b-form-group label="Card" label-for="card">
              <b-form-input id="card" v-model="item.card" :state="state('card')" type="text" placeholder="Card number" />
            </b-form-group>
            <b-form-group v-if="!item.id" label="Pin" label-for="pin">
              <b-input-group>
                <b-form-input id="pin" v-model="item.pin" :state="state('pin')" :type="showPin ? 'text' : 'password'" placeholder="Card pin code (optional)" />
                <b-input-group-append>
                  <b-button @click.prevent="" @mousedown.prevent="showPin = true" @mouseup.prevent="showPin = false">
                    <fa-icon icon="eye" />
                  </b-button>
                  <b-button @click.prevent="item.pin = generatePin(); showPin = true; setTimeout(() => { showPin = false; }, 500);">
                    <fa-icon icon="cogs" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCogs, faEye,
} from '@fortawesome/free-solid-svg-icons';
import utils from '../../../libs/utils.vue';

library.add(faCogs, faEye);

export default {
  data () {
    return {
      showPin: false,
      errors: {},
      cardId: undefined,
      selectedItem: {},
      where: {},
      groups: [],
      q: null,
      fields: [
        {
          key: 'firstname',
          sortable: true,
        },
        {
          key: 'lastname',
          sortable: true,
        },
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
        },
      ],
    };
  },
  watch: {
    groups () {
      this.where = {
        ...utils.parseWhereItem('groups.id', this.groups),
      };
    },
  },
  methods: {
    setTimeout (...args) {
      return window.setTimeout(...args);
    },
    generatePin () {
      return Math.floor(Math.random() * (999999 - 100000 + 1) + 100000).toString();
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    groupsState (item) {
      return (this.errors[item] ? false : null);
    },
    cardGroups (item) {
      const { id } = item;
      this.selectedItem = {};
      this.cardId = id;
      this.$http.get(`cards/${id}/groups`)
        .then((res) => {
          this.selectedItem.groups = res.data;
          this.errors = {};
          this.$refs.groupsmodal.show();
        })
        .catch(this.err);
    },
    saveGroups () {
      this.$http.patch(`cards/${this.cardId}/groups`, this.selectedItem.groups || [])
        .then(() => {
          this.$refs.groupsmodal.hide();
        })
        .catch(this.err);
    },
  },
};
</script>
